import { useEffect, useState, useCallback } from "react";

const useIsMobile = (): boolean => {
  const [width, setWidth] = useState<number>(
    typeof window !== "undefined" ? window.innerWidth : null
  );

  const handleWindowSizeChange = useCallback(
    () => setWidth(window.innerWidth),
    [width]
  );

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return width && width <= 768;
};

export default useIsMobile;
