import React from "react";
import { Center, SimpleGrid, Spinner } from "@chakra-ui/react";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { Website } from "@/types/website";
import { CloudType } from "@/types/cloud";
import { usePressOffice } from "@/hooks/usePress";
import Excerpt from "../News/Excerpt";
import { useSearch } from "./SearchContext";

type Props = {
  website: Website;
  category?: CloudType;
};

const Page = ({ website, category }: Props): React.ReactElement => {
  const { searchTerm } = useSearch();
  const { articles, isLoading, hasNextPage, error, loadMore } = usePressOffice(
    website?.pressOffice?.id || website?.organization?.id,
    category,
    !!website?.organization,
    searchTerm
  );
  const [sentryRef] = useInfiniteScroll({
    hasNextPage,
    disabled: !!error,
    loading: isLoading,
    onLoadMore: loadMore,
  });

  return (
    <>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6}>
        {articles?.map((article) => (
          <Excerpt
            key={`article-${article.id}`}
            article={article}
            website={website}
            rounded={false}
          />
        ))}
        {(isLoading || hasNextPage) && (
          <Center ref={sentryRef} mt="2">
            <Spinner />
          </Center>
        )}
      </SimpleGrid>
    </>
  );
};

export default Page;
