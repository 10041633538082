import { useCallback, useMemo } from "react";
import useSWRInfinite from "swr/infinite";
import queryString from "query-string";
import useSWR from "swr";

import { Article } from "@/types/office";
import { CloudType } from "@/types/cloud";
import { typedFile } from "@/utils/typedFile";

import { useStaleSWR } from "./useStaleSWR";

export const usePress = (
  id?: string,
  officeId?: string
): {
  isLoading?: boolean;
  article?: Article;
  articles?: Article[];
} => {
  const { data: dataPress, error } = id
    ? useStaleSWR(`/press/${id}`)
    : officeId
    ? useSWR(`/press/office/${officeId}`)
    : useSWR("/press");

  const isLoading = !dataPress && !error;

  if (!dataPress) {
    return {
      isLoading,
      article: null,
      articles: null,
    };
  }

  if (id) {
    if (dataPress?.webFile) {
      dataPress.webFile = typedFile(dataPress.webFile);
    }

    if (dataPress?.facebookFiles) {
      dataPress.facebookFiles = (dataPress?.facebookFiles || [])?.map((file) =>
        typedFile(file)
      );
    }

    if (dataPress?.twitterFiles) {
      dataPress.twitterFiles = (dataPress?.twitterFiles || [])?.map((file) =>
        typedFile(file)
      );
    }

    return {
      article: dataPress,
    };
  }

  return {
    articles: (dataPress || [])?.map((article) => ({
      ...article,
      webFile: typedFile(article.webFile),
      facebookFiles: (article?.facebookFiles || [])?.map((file) =>
        typedFile(file)
      ),
      twitterFiles: (article?.twitterFiles || [])?.map((file) =>
        typedFile(file)
      ),
    })),
  };
};

export const useNewsletter = (
  id?: string,
  searchValue?: string
): {
  isLoading?: boolean;
  article?: Article;
  articles?: Article[];
} => {
  const { data: dataPress, error } = id
    ? useStaleSWR(`/press/${id}`)
    : useSWR(`/press/newsletter${searchValue ? `?search=${searchValue}` : ""}`);
  const isLoading = !dataPress || !error;

  if (!dataPress) {
    return {
      isLoading,
      article: null,
      articles: null,
    };
  }

  if (id) {
    if (dataPress?.emailFiles) {
      dataPress.emailFiles = dataPress.emailFiles.map((file) =>
        typedFile(file)
      );
    }

    return {
      article: dataPress,
    };
  }

  return {
    articles: (dataPress || [])?.map((article) => ({
      ...article,
      emailFiles: (article?.emailFiles || [])?.map((file) => typedFile(file)),
    })),
  };
};

const getPressUserKey = (pageIndex, previousPageData) => {
  if (previousPageData && !previousPageData.nextPage) return null;
  return `/press/user?page=${pageIndex}&take=6`;
};

export const usePressUser = (): {
  error?: any;
  isLoading?: boolean;
  articles?: Article[];
  hasNextPage?: boolean;
  loadMore?: () => void;
} => {
  const { data: pages, setSize, error } = useSWRInfinite(getPressUserKey);
  const isLoading = !pages && !error;

  const loadMore = useCallback(() => {
    setSize((size) => size + 1);
  }, []);
  const articles = useMemo(
    () => (pages || [])?.map((page) => page?.data).flat(),
    [pages]
  );
  const hasNextPage =
    pages?.length > 0 ? !!pages[pages.length - 1]?.nextPage : false;

  if (!articles) {
    return {
      isLoading,
      articles: null,
      hasNextPage: false,
    };
  }

  return {
    articles: (articles || [])?.map((article) => ({
      ...article,
      webFile: typedFile(article?.webFile),
    })),
    error,
    loadMore,
    isLoading,
    hasNextPage,
  };
};

const getPressOfficeKey =
  (isOrganization, officeId, mimetype, searchTerm = null) =>
  (pageIndex, previousPageData) => {
    if ((previousPageData && !previousPageData.nextPage) || !officeId)
      return null;
    const officeType = isOrganization ? "organization" : "office";
    const apiUrl = `/press/${officeType}/${officeId}${
      mimetype ? `/${mimetype}` : ""
    }`;
    const queryParams = queryString.stringify({
      take: 6,
      page: pageIndex,
      search: searchTerm,
    });

    return `${apiUrl}?${queryParams}`;
  };

export const usePressOffice = (
  officeId: number,
  category?: CloudType,
  isOrganization = false,
  searchTerm = undefined
): {
  error?: any;
  isLoading?: boolean;
  articles?: Article[];
  hasNextPage?: boolean;
  loadMore?: () => void;
} => {
  const mimetype = category == CloudType.PHOTO ? "image" : category;
  const getKey = getPressOfficeKey(
    isOrganization,
    officeId,
    mimetype,
    searchTerm
  );
  const { data: pages, setSize, error } = useSWRInfinite(getKey);
  const isLoading = !pages && !error;
  const loadMore = useCallback(() => {
    setSize((size) => size + 1);
  }, [setSize]);
  const articles = useMemo(
    () => (pages || [])?.map((page) => page?.data).flat(),
    [pages]
  );
  const hasNextPage =
    pages?.length > 0 ? !!pages[pages.length - 1].nextPage : false;

  if (!articles) {
    return {
      error,
      isLoading,
      hasNextPage,
      articles: null,
    };
  }

  return {
    articles: (articles || [])?.map((article) => ({
      ...article,
      webFile: typedFile(article.webFile),
    })),
    error,
    loadMore,
    isLoading,
    hasNextPage,
  };
};

export const usePressOrganization = (
  organizationId: number,
  category?: CloudType,
  isOrganization = true
): {
  error?: any;
  isLoading?: boolean;
  articles?: Article[];
  hasNextPage?: boolean;
  loadMore?: () => void;
} => {
  const mimetype = category == CloudType.PHOTO ? "image" : category;
  const getKey = getPressOfficeKey(isOrganization, organizationId, mimetype);
  const { data: pages, setSize, error } = useSWRInfinite(getKey);
  const isLoading = !pages && !error;
  const loadMore = useCallback(() => {
    setSize((size) => size + 1);
  }, []);
  const articles = useMemo(
    () => (pages || [])?.map((page) => page?.data).flat(),
    [pages]
  );
  const hasNextPage =
    pages?.length > 0 ? !!pages[pages.length - 1].nextPage : false;

  if (!articles) {
    return {
      error,
      isLoading,
      hasNextPage,
      articles: null,
    };
  }

  return {
    articles: (articles || [])?.map((article) => ({
      ...article,
      webFile: typedFile(article.webFile),
    })),
    error,
    loadMore,
    isLoading,
    hasNextPage,
  };
};
