import React from "react";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import Layout from "@/components/Page/Layout";
import Page from "@/components/Page";
import { TFunction } from "next-i18next";
import { serverSidePage } from "@/utils/serverSide";
import { Website } from "@/types/website";
import { GetServerSideProps } from "next";

type Props = {
  website: Website;
};

export const PressPage = ({ website }: Props): React.ReactElement => (
  <Page website={website} />
);

PressPage.getLayout = function getLayout(
  page: React.ReactElement,
  t: TFunction
) {
  const { website } = page.props;

  return (
    <Layout title={t("press.pressInfo")} website={website}>
      {page}
    </Layout>
  );
};

export const getServerSideProps: GetServerSideProps = async ({
  locale,
  query,
  req,
}) => ({
  props: {
    ...(await serverSidePage(query, req)),
    ...(await serverSideTranslations(locale, ["common"])),
  },
});

export default PressPage;
